const config = {
    api: {
        baseUrl: process.env.VUE_APP_API_BASE_URL
    },
    app: {
        baseUrl: process.env.VUE_APP_BASE_URL
    }
};

(() => {
    if (!config.api.baseUrl) {
        // eslint-disable-next-line no-console
        console.error(
            "No value provided for required environment variable: VUE_APP_API_BASE_URL"
        );
    }

    if (!config.app.baseUrl) {
        // eslint-disable-next-line no-console
        console.error(
            "No value provided for required environment variable: VUE_APP_BASE_URL"
        );
    }
})();

export default config