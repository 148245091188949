import asnGenApi from "../../api/asn-gen-api"

const state = () => ({
    status: {
        loading: false,
        message: ""
    },
    purchaseOrders: [],
    selectedPurchaseOrderIds: []
})

const getters = {
    getStatus: state => { return state.status },
    getPurchaseOrders: state => { return state.purchaseOrders },
    getSelectedPurchaseOrderIds: state => { return state.selectedPurchaseOrderIds },
    getSelectedPurchaseOrders: state => { 
        const results = []
        for (var i=0; i < state.selectedPurchaseOrderIds.length; i++) {
            results.push(state.purchaseOrders.find(po => { return po.data.id == state.selectedPurchaseOrderIds[i]}))
        } 
        return results;
    },
    
}

const actions = {
    loadPurchaseOrders({ commit }) {
        updateStatus(commit, true, "Loading Purchase Orders...")
        asnGenApi.getPurchaseOrders()
            .then(results => {
                updatePurchaseOrders(commit, results)
                updateStatus(commit, false, "Loading complete!")
            })
            .catch(err => {
                updateStatus(commit, false, "Loading error! " + err)
            })
    },
    loadVendorPurchaseOrders({ commit }, vendorId) {
        updateStatus(commit, true, `Loading Purchase Orders for Vendor ${vendorId}...`)
        asnGenApi.getPurchaseOrdersByVendor(vendorId)
            .then(results => {
                updatePurchaseOrders(commit, results)
                updateStatus(commit, false, "Loading complete!")
            })
            .catch(err => {
                updateStatus(commit, false, "Loading error! " + err)
            })
    },
    toggleSelectedPurchaseOrder({ commit, state}, purchaseOrder) {
        if (state.selectedPurchaseOrderIds.filter(po => po === purchaseOrder.data.id).length>0)
            updateSelectedPurchaseOrderIds(commit, state.selectedPurchaseOrderIds.filter(po => po !== purchaseOrder.data.id))
        else
            updateSelectedPurchaseOrderIds(commit, [...state.selectedPurchaseOrderIds, purchaseOrder.data.id])
    },
    deSelectPurchaseOrders({commit}) {
        updateSelectedPurchaseOrderIds(commit, [])
    }
}

const mutations = {
    setPurchaseOrders(state, purchaseOrders) {
        state.purchaseOrders = purchaseOrders
    },
    setSelectedPurchaseOrderIds(state, purchaseOrderIds) {
        state.selectedPurchaseOrderIds = purchaseOrderIds
    },
    setStatus(state, status) {
        state.status = status
    }
}

const updatePurchaseOrders = (commit, results) => {
    commit('setPurchaseOrders', results)
}

const updateSelectedPurchaseOrderIds = (commit, results) => {
    commit('setSelectedPurchaseOrderIds', results)
}

const updateStatus = (commit, loading, message) => {
    commit('setStatus', {loading, message})
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}