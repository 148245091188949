import masterGenApi from "../../api/master-gen-api";

const state = () => ({
    status: {
        loading: false,
        message: ""
    },
    selectedCdmType: "NONE",
    genResponse: {}
})

const getters = {
    getSelectedCdmType: state => state.selectedCdmType,
    getStatus: state => state.status,
    getGenResponse: state => state.genResponse
}

const actions = {
    setSelectedCdmType({ commit }, cdmType) {
        updateStatus(commit, true, "Setting CDM Type...");
        updateSelectedCdmType(commit, cdmType);
        updateStatus(commit, false, "Finished setting CDM Type");
    },
    createNewVendorCDM({ commit }, {vendorData, generate}) {
        updateStatus(commit, true, "Creating Vendor CDM...");
        masterGenApi.createVendor(vendorData, generate).then(res => {
            updateGenResponse(commit, res);
            updateStatus(commit, false, "Finished Creating Vendor CDM");
        });
    },
    createNewItemCDM({ commit }, {itemData, generate}) {
        updateStatus(commit, true, "Creating Item CDMs...");
        masterGenApi.createItem(itemData, generate).then(res => {
            updateGenResponse(commit, res);
            updateStatus(commit, false, "Finished Creating Item CDMs");
        });
    },
    createNewCustomerCDM({ commit }, {customerData, generate}) {
        updateStatus(commit, true, "Creating Customer CDM...");
        masterGenApi.createCustomer(customerData, generate).then(res => {
            updateGenResponse(commit, res);
            updateStatus(commit, false, "Finished Creating Customer CDM");
        });
    },
    createNewManufacturerCDM({ commit }, {manufacturerData, generate}) {
        updateStatus(commit, true, "Creating Manufacturer CDM...");
        masterGenApi.createManufacturer(manufacturerData, generate).then(res => {
            updateGenResponse(commit, res);
            updateStatus(commit, false, "Finished Creating Manufacturer CDM");
        });
    },
    
}

const mutations = {
    setStatus(state, status) {
        state.status = status
    },
    setSelectedCdmType(state, selectedCdmType) {
        state.selectedCdmType = selectedCdmType
    },
    setGenResponse(state, genResponse) {
        state.genResponse = genResponse
    }
}

const updateStatus = (commit, loading, message) => {
    commit('setStatus', {loading, message})
}

const updateSelectedCdmType = (commit, cdmType) => {
    commit('setSelectedCdmType', cdmType)
}

const updateGenResponse = (commit, genResponse) => {
    commit('setGenResponse', genResponse)
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}