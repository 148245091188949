import asnGenApi from "../../api/asn-gen-api"
import { ContainerTypes, Asn } from "../../api/asn"

const state = () => ({
    status: {
        loading: false,
        message: ""
    },
    addStatus: {
        loading: false,
        message: "",
        error: false
    },
    loadAsnCdmStatus: {
        loading: false,
        message: "",
        error: false
    },
    containerOptions: {
        packs: [],
        tares: []
    },
    vendors: [],
    purchaseOrders: [],
    asnModel: new Asn(),
    asnCdmModelResponse: {},
    containerTypes: [{ name: ContainerTypes.PACK }, { name: ContainerTypes.TARE }]
})

const getters = {
    getStatus: state => { return state.status },
    getAddStatus: state => { return state.addStatus },
    getLoadAsnCdmStatus: state => { return state.loadAsnCdmStatus },
    getVendors: state => { return state.vendors },
    getPurchaseOrders: state => { return state.purchaseOrders },
    getContainerOptions: state => { return state.containerOptions },
    getAsnModel: state => { return state.asnModel },
    getAsnCdmModelResponse: state => { return state.asnCdmModelResponse },
    getContainerTypes: state => { return state.containerTypes }
}

const actions = {
    loadVendors({ commit }) {
        updateStatus(commit, true, false, "Loading...")

        asnGenApi.getVendors()
            .then(result => {
                updateVendors(commit, result)
                updateStatus(commit, false, false, "Loading complete!")
            })
            .catch(err => {
                updateStatus(commit, false, true, "Loading error! " + err)
            })
    },
    loadPurchaseOrder({ commit }, purchaseOrderId) {
        updateStatus(commit, true, false, "Loading...")

        asnGenApi.getPurchaseOrder(purchaseOrderId)
            .then(result => {
                insertPurchaseOrder(commit, result)
                updateStatus(commit, false, false, "Loading complete!")
            })
            .catch(err => {
                updateStatus(commit, false, true, "Loading error! " + err)
            })
    },
    removePurchaseOrder({ commit }, purchaseOrder) {
        deletePurchaseOrder(commit, purchaseOrder)
    },
    removeAllPurchaseOrders({ commit }) {
        deleteAllPurchaseOrder(commit)
    },
    clearAsnModel({ commit }) {
        clearAsnModel(commit)
    },
    clearAsnModelLines({ commit }) {
        clearAsnModelLines(commit)
    },
    setAsnNumber({ commit }, number) {
        updateAsnNumber(commit, number)
    },
    setAsnVendor({ commit }, vendor) {
        updateAsnVendor(commit, vendor)
    },
    setAsnToInformation({ commit }, toInformation) {
        updateAsnToInformation(commit, toInformation)
    },
    addAsnContainer({ commit }, { type, id }) {
        updateAddStatus(commit, true, false, "Adding new ASN Container...")
        try {
            if (type !== ContainerTypes.PACK && type !== ContainerTypes.TARE)
                throw Error("Not a valid container type for Asn!")

            const invalidChars = ["-", "="]
            //validate id field
            if (id.length == 0)
                throw Error("Enter a valid id for this container!")

            for (var i = 0; i < invalidChars.length; i++) {
                if (id.indexOf(invalidChars[i]) !== -1)
                    throw Error(`Id contains invalid character '${invalidChars[i]}'!`)
            }

            insertAsnContainer(commit, id, type)
            updateAddStatus(commit, false, false, "Added new ASN Container!")
        }
        catch (err) {
            updateAddStatus(commit, false, true, "Error adding new Asn Container: " + err)
        }
    },
    addAsnLine({ commit }, { packId, tareId, itemCode, vendorItemCode, poNumber, poLineNumber, poExternalRef, poOrganization, packedQuantity }) {
        updateAddStatus(commit, true, false, "Adding new ASN Line...")
        try {
            insertAsnLine(commit, { packId, tareId, itemCode, vendorItemCode, poNumber, poLineNumber, poExternalRef, poOrganization, packedQuantity })
            updateAddStatus(commit, false, false, "Added new Asn Line!")
        }
        catch (err) {
            updateAddStatus(commit, false, true, "Error adding new Asn Line: " + err)
        }
    },
    loadAsnCdmModelResponse({ commit, state }, generate) {
        updateLoadAsnCdmStatus(commit, true, false, "Loading Cdm Model for ASN...")

        updateAsnCdmModelResponse(commit, {})

        //ASN Model validation
        if (!state.asnModel.number || state.asnModel.number.length === 0) {
            updateLoadAsnCdmStatus(commit, false, true, "Error: ASN is missing a number!")
            return
        }
        else if (!state.asnModel.vendor || state.asnModel.vendor.length === 0) {
            updateLoadAsnCdmStatus(commit, false, true, "Error: ASN is missing a vendor!")
            return
        }
        else if (!state.asnModel.lines || state.asnModel.lines.length === 0) {
            updateLoadAsnCdmStatus(commit, false, true, "Error: ASN is missing line items! Please add PO lines to ASN before generating.")
            return
        }

        asnGenApi.getAsnCdmModel(state.asnModel, generate)
            .then(res => {
                if (res.success === false) {
                    updateLoadAsnCdmStatus(commit, false, true, `Error: ${res.message}`)
                }
                else {
                    updateAsnCdmModelResponse(commit, res)
                    updateLoadAsnCdmStatus(commit, false, false, "Finished Loading Cdm Model for ASN")
                    updateStatus(commit, false, false, "Finished Loading Cdm Model for ASN")
                }
            })
    },
    resetAddStatus({ commit }) {
        updateAddStatus(commit, false, false, "")
    }
}

const mutations = {
    setStatus(state, status) {
        state.status = status
    },
    setAddStatus(state, status) {
        state.addStatus = status
    },
    setLoadAsnCdmStatus(state, status) {
        state.loadAsnCdmStatus = status
    },
    addPurchaseOrder(state, purchaseOrder) {
        if (!state.purchaseOrders.find(po => po.PurchaseOrderNumber === purchaseOrder.PurchaseOrderNumber))
            state.purchaseOrders = [...state.purchaseOrders, purchaseOrder]
    },
    removePurchaseOrder(state, purchaseOrder) {
        state.purchaseOrders = state.purchaseOrders.map(po => po.id != purchaseOrder.id)
    },
    removeAllPurchaseOrders(state) {
        state.purchaseOrders = []
    },
    clearAsnModel(state) {
        state.asnModel.clear()
    },
    clearAsnModelLines(state) {
        state.asnModel.clearLines()
    },
    setAsnNumber(state, number) {
        state.asnModel.number = number
    },
    setAsnVendor(state, vendor) {
        state.asnModel.vendor = vendor
    },
    setAsnToInformation(state, toInformation) {
        state.asnModel.to = toInformation
    },
    setVendors(state, vendors) {
        state.vendors = vendors
    },
    addAsnContainer(state, { id, type }) {
        
        if (type === ContainerTypes.PACK) {
            state.containerOptions.packs.push({ id, type })
        }
        else if (type === ContainerTypes.TARE) {
            state.containerOptions.tares.push({ id, type })
        }
    },
    addAsnLine(state, asnLine) {

        //Add containers to ASN model if needed
        let pack = state.asnModel.packs.find(currPack => {
            return currPack.index == asnLine.packId
        })
        if (!pack) {
            pack = state.asnModel.newContainer(asnLine.packId, ContainerTypes.PACK)
            state.asnModel.packs.push(pack)
        }

        if (asnLine.tareId && asnLine.tareId.length > 0) {
            let tare = state.asnModel.tares.find(currTare => {
                return currTare.index == asnLine.tareId
            })
            if (!tare) {
                tare = state.asnModel.newContainer(asnLine.tareId, ContainerTypes.TARE)
                state.asnModel.tares.push(tare)
            }
        }

        //Add Asn Line
        let line = state.asnModel.newAsnLine(
            asnLine.packId, asnLine.tareId, asnLine.itemCode, asnLine.vendorItemCode,
            asnLine.poNumber, asnLine.poLineNumber, asnLine.poExternalRef, asnLine.poOrganization,
            asnLine.packedQuantity, asnLine.orderedQuantity)
        state.asnModel.lines.push(line)
    },
    setAsnCdmModelResponse(state, asnCdmModelResponse) {
        state.asnCdmModelResponse = asnCdmModelResponse
    }
}

const updateStatus = (commit, loading, error, message) => {
    commit('setStatus', { loading, error, message })
}

const updateAddStatus = (commit, loading, error, message) => {
    commit('setAddStatus', { loading, error, message })
}

const updateLoadAsnCdmStatus = (commit, loading, error, message) => {
    commit('setLoadAsnCdmStatus', { loading, error, message })
}

const updateVendors = (commit, vendors) => {
    commit('setVendors', vendors)
}

const insertPurchaseOrder = (commit, purchaseOrder) => {
    commit('addPurchaseOrder', purchaseOrder)
}

const deletePurchaseOrder = (commit, purchaseOrder) => {
    commit('removePurchaseOrder', purchaseOrder)
}

const deleteAllPurchaseOrder = (commit) => {
    commit('removeAllPurchaseOrders')
}

const clearAsnModel = (commit) => {
    commit('clearAsnModel')
}

const clearAsnModelLines = (commit) => {
    commit('clearAsnModelLines')
}

const updateAsnNumber = (commit, number) => {
    commit('setAsnNumber', number)
}

const updateAsnVendor = (commit, vendor) => {
    commit('setAsnVendor', vendor)
}

const insertAsnContainer = (commit, id, type) => {
    commit('addAsnContainer', { id, type })
}

const insertAsnLine = (commit, asnLine) => {
    commit('addAsnLine', asnLine)
}

const updateAsnCdmModelResponse = (commit, asnCdmModelResponse) => {
    commit('setAsnCdmModelResponse', asnCdmModelResponse)
}

const updateAsnToInformation = (commit, toInformation) => {
    commit('setAsnToInformation', toInformation)
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}