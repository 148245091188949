import config from "../config/config";

const limit = 20

export default {
    getVendors() {
        return new Promise((resolve, reject) => {
            fetch(`${getEndpoint()}/vendors/`)
                .then(res => {
                    res.json().then(res => {
                        resolve(res)
                    })
                })
                .catch(err => {
                    reject(err)
                })          
        })
    },
    getPurchaseOrders() {
        return new Promise((resolve, reject) => {
            fetch(`${getEndpoint()}/purchase-orders/?limit=${limit}`)
                .then(res => {
                    res.json().then(res => {
                        resolve(res.results)
                    })
                })
                .catch(err => {
                    reject(err)
                })          
        })
    },
    getPurchaseOrdersByVendor(vendorId) {
        return new Promise((resolve, reject) => {
            fetch(`${getEndpoint()}/vendors/${vendorId}/purchase-orders/?limit=${limit}`)
                .then(res => {
                    res.json().then(res => {
                        resolve(res.results)
                    })
                })
                .catch(err => {
                    reject(err)
                })     
        })
    },
    getPurchaseOrder(id) {
        return new Promise((resolve, reject) => {
            fetch(`${getEndpoint()}/purchase-orders/${id}/cdm`)
                .then(res => {
                    res.json().then(res => {
                        fixPOLineItemsFields(res.LineItem)
                        resolve(res)
                    })
                })
                .catch(err => {
                    reject(err)
                })          
        })
    },
    getAsnCdmModel(asnData, generate = false) {
        return new Promise((resolve, reject) => {
            let fetchOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: asnData }) 
            }
            fetch(`${getEndpoint()}/asn?generate=${generate ? 'yes' : 'no'}`, fetchOptions)
                .then(res => {
                    res.json().then(res => {
                        resolve(res)
                    })
                })
                .catch(err => {
                    reject(err)
                }) 
        })
    }
}

function getEndpoint() {
    const endpoint = config.api.baseUrl
    if (!endpoint)
        throw Error("Invalid API Endpoint!")
    return endpoint
}

//add missing info to PO LineItems
function fixPOLineItemsFields(lineItems) {
    for (var i=0; i < lineItems.length; i++) {
        
        if (!lineItems[i].ExpectedDate)
            lineItems[i].ExpectedDate = new Date()
        
        if (!lineItems[i].ExpectedQuantity)
            lineItems[i].ExpectedQuantity = 0

        if (!lineItems[i].ExpectedQuantity)
            lineItems[i].ExpectedQuantity = 0
        
        if (!lineItems[i].PurchasingUomMultiplier)
            lineItems[i].PurchasingUomMultiplier = 1

        if (!lineItems[i].ReceivedToDateQuantity)
            lineItems[i].ReceivedToDateQuantity = 0        
    }
}