export function Asn() {
    this.number = "",
    this.vendor ="",
    this.packs = [],
    this.tares = [],
    this.lines = [],
    this.to = {
        code: "",
        name: "",
        address: {
            address1: "",
            address2: "",
            address3: "",
            city: "",
            stateOrProvince: "",
            country: "",
            zipOrPostalCode: ""
        }
    }
    
    this.clear = () => {
        this.number = ""
        this.vendor = ""
        this.packs = []
        this.tares = []
        this.lines = []
        this.to = {
            code: "",
            name: "",
            address: {
                address1: "",
                address2: "",
                address3: "",
                city: "",
                stateOrProvince: "",
                country: "",
                zipOrPostalCode: ""
            }
        }
    }

    this.clearLines = () => {
        this.packs = []
        this.tares = []
        this.lines = []
    }

    this.newContainer = (containerId, type) => {
        let container = new Container();
        container.index = containerId;
        container.type = type
        if (!this.containerExists(container))
            return container    
        else
            throw Error("container with index already exists!")
    }

    this.newAsnLine = (packId, tareId, itemCode, vendorItemCode, poNumber, poLineNumber, poExternalRef, poOrganization, packedQuantity, orderedQuantity = packedQuantity) => {
        if (!packId || packId.length === 0) 
            throw Error("No pack selected!")

        if (packedQuantity <= 0)
            throw Error("Invalid Quantity!")

        if (!this.containerExists({index: packId, type: ContainerTypes.PACK}))
            throw Error("pack does not exist!")
        
        // if (!this.containerExists({index: tareId, type: ContainerTypes.TARE}))
        //     throw Error("Tare does not exist!")
        
        //If another tare contains the same package, raise an error
        const tareForPackId = this.findTareForPack(packId)
        if (tareForPackId && tareForPackId.index != tareId)
            throw Error("Pack already exists in a different tare!")

        let newLine = new AsnLine()
        newLine.shipToPONumber = poExternalRef || poNumber
        newLine.shipToPOExternalReference = poExternalRef
        newLine.shipToPOOrganization = poOrganization
        newLine.lineNumber = poLineNumber
        newLine.itemCode = itemCode
        newLine.vendorItemCode = vendorItemCode
        newLine.packedQuantity = packedQuantity
        newLine.orderedQuantity = orderedQuantity
        newLine.tare.index = tareId
        newLine.pack.index = packId
        return newLine
    }

    this.containerExists = (container) => {
        if (this.tares.find(existing =>  existing.index == container.index) 
            || this.packs.find(existing =>  existing.index == container.index))
            return true
        return false
    }

    this.packExists = (container) => {
        if (this.packs.find(existing =>  existing.index == container.index))
            return true
        return false
    }

    this.tareExists = (container) => {
        if (this.tares.find(existing =>  existing.index == container.index))
            return true
        return false
    }

    this.findTareForPack = (packIndex) => {
        const lineWithPack = this.lines.find(line => { return line.pack.index === packIndex})
        if (lineWithPack)
            return lineWithPack.tare
    }
}

function Container() {
    this.index = ""
    this.type = ""
}

function AsnLine() {
    this.lineNumber = ""
    this.shipToPONumber = ""
    this.shipToPOOrganization = ""
    this.shipToPOExternalReference = ""
    this.itemCode = ""   
    this.vendorItemCode = "" 
    this.orderedQuantity = 0
    this.packedQuantity = 0
    this.pack = new Container()
    this.tare = new Container()
}   


export const ContainerTypes = {
    PACK: "pack",
    TARE: "tare"
}
