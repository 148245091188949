import { createStore } from 'vuex'
import purchaseOrders from './modules/purchase-orders'
import asnGen from './modules/asn-gen'
import masterDataGen from './modules/master-data-gen'
import purchaseOrdersGen from './modules/purchase-orders-gen'

export default createStore({
    modules: {
        purchaseOrders,
        asnGen,
        masterDataGen,
        purchaseOrdersGen
    },
    strict: 'debug'
})